<template>
  <div>
    <AppBar />

    <SideMenu
      v-if="$vuetify.breakpoint.smAndUp"
    />

    <v-main>
      <v-container>
        <v-scroll-x-transition :hide-on-leave="true">
          <router-view />
        </v-scroll-x-transition>
      </v-container>
    </v-main>

    <BottomMenu
      v-if="$vuetify.breakpoint.mdAndDown"
    />

    <CompleteOnboardingModal />

    <ReconnectModal />

  </div>
</template>

<script>
import lazyLoad from '@/utils/lazyLoad';

export default {
  name: 'PrivateLayout',
  components: {
    AppBar: lazyLoad('layouts/Private/AppBar'),
    SideMenu: lazyLoad('layouts/Private/SideMenu'),
    BottomMenu: lazyLoad('layouts/Private/BottomMenu'),
    CompleteOnboardingModal: lazyLoad('layouts/Private/CompleteOnboardingModal'),
    ReconnectModal: lazyLoad('layouts/Private/ReconnectModal'),
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 80vw !important;
  max-width: 90vw !important;
}
.desktop {
  position: fixed !important;
  right: 15px;
  bottom: 15px;
}
.mobile {
  position: fixed !important;
  right: 15px;
  bottom: 70px;
}
@media (max-width: 600px) {
  .container {
    width: 100vw !important;
  }
}
</style>
